const faqs = [
  {
    question: "1. ¿Qué incluye un seguro de viaje internacional?",
    answer: (
      <div>
        <p>
          {" "}
          Un seguro de viaje internacional generalmente incluye cobertura
          médica, asistencia en viajes, cobertura de equipaje, cancelación de
          vuelos y repatriación. Dependiendo del plan, puede incluir adicionales
          como deportes extremos o enfermedades preexistentes. .
        </p>
      </div>
    ),
  },
  {
    question: "2. ¿Cuánto cuesta un seguro de viaje internacional?",
    answer: (
      <div>
        <p>
          {" "}
          El costo de un seguro de viaje internacional varía según la duración
          del viaje, el destino, la edad del viajero y la cobertura
          seleccionada. En promedio, puede oscilar entre $50 y $200 USD por
          semana de viaje.
        </p>
      </div>
    ),
  },
  {
    question: "3. ¿Cómo puedo cotizar un seguro de viaje?",
    answer: (
      <div>
        <p>
          Cotizar un seguro de viaje es sencillo. Puedes hacerlo online a través
          de la página web de la aseguradora, ingresando los detalles de tu
          viaje y tus necesidades. Esto te permitirá comparar diferentes planes
          y precios.
        </p>
      </div>
    ),
  },
  {
    question: "4. ¿Es obligatorio tener un seguro de viaje internacional?",
    answer: (
      <div>
        <p>
          En algunos países, contar con un seguro de viaje es un requisito de
          entrada. Además, tener un seguro es altamente recomendable para
          protegerte de imprevistos y garantizar una experiencia de viaje segura
          y tranquila.
        </p>
      </div>
    ),
  },
];

export default faqs;
